/* ----------------------------------------------------------- */
/*         Aiomax JS Library - © Aiomax Group 2023             */
/* ----------------------------------------------------------- */
/**
 * Import objects
 */
import {polaris, slideshow} from "./modules/config";
import {loadPage, websiteNav} from "./modules/core";


/**
 * Aiomax object
 */
const Aiomax = {
    Polaris:    polaris,
    Slideshow:  slideshow,
    loadPage:   loadPage,
    websiteNav: websiteNav
}


/**
 * Export default
 */
export default Aiomax;